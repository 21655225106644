export const PopGames = [
  {
    name:"BFFs Unique Halloween Costumes",
    path:"/game/bffs-unique-halloween-costumes/",
  },
  {
    name:"Angela Halloween Preparation",
    path:"/game/angela-halloween-preparation/",
  },
  {
    name:"Drive Mad 2",
    path:"/game/drive-mad-2/",
  },
  {
    name:"My Musical Love Story",
    path:"/game/my-musical-love-story/",
  },
  {
    name:"Fashion Intern A Journey into Style",
    path:"/game/fashion-intern-a-journey-into-style/",
  },
  {
    name:"Lovie Chics In Fantasy World",
    path:"/game/lovie-chics-in-fantasy-world/",
  },
  {
    name:"Find The Correct Shadow",
    path:"/game/find-the-correct-shadow",
  },{
    name:"Makeup Slime Cooking Master 2",
    path:"/game/makeup-slime-cooking-master-2/",
  },
  {
    name:"Celebrity E-Girl vs Soft-Girl",
    path:"/game/celebrity-e-girl-vs-soft-girl/",
  },
  {
    name:"Nightmare Couple Halloween Party",
    path:"/game/nightmare-couple-halloween-party/",
  },
  {
    name:"Gacha Club",
    path:"/game/gacha-club/",
  },
  {
    name:"Celebrity Last Fling Before The Ring",
    path:"/game/celebrity-last-fling-before-the-ring/",
  },
];
